import React from "react"
import { graphql } from "gatsby"
import { Row, Col, Container, ListGroup, Carousel } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ImageCarousel = ({ data }) => (
  data.allFile.edges.map((item) => {
    return (
      <Carousel.Item>
        <GatsbyImage image={getImage(item.node.childImageSharp.fixed)}  />
      </Carousel.Item>
    )
  })
)

const IndexPage = ({data}) => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Home" keywords={[`unhistoried`, `indonesia`, `brrrlabs`]} />
    <Container className="text-center">
      <Row noGutters className="unhistoried-index-slideshow-row">
        <Col md={12} className="unhistoried-index-slideshow-col">
          <Carousel fade controls={false} indicators={false}>
            {data.contentfulGeneral.indexPageImages.map((item, index) => {
              return (
                <Carousel.Item interval={2500}>
                  <GatsbyImage className="landing-image" image={item.gatsbyImageData} />
                </Carousel.Item>
              )
            })}
          </Carousel>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export const imgQuery = graphql`
query LandingImgQuery {
  contentfulGeneral {
    indexPageImages {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
  }
}`

export default IndexPage
